export const Types = {
  OPEN_CHAT: "chat/OPEN_CHAT",
  FILTER_CHAT: "chat/FILTER_CHAT",
  SEND_MSG: "chat/SEND_MSG"
};

const INITIAL_STATE = {
  chatData: [ ]
};

export default function chats(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SEND_MSG:
      return state.map(chat =>
        chat.id === action.payload.id
          ? Object.assign(
              {},
              chat,
              chat.chatHistory[0][1]["to"].push(action.payload.chatMsg)
            )
          : chat
      );
    case Types.OPEN_CHAT:
      return action.payload.id;
    case Types.FILTER_CHAT:
      return action.payload.searchTerm;
    default:
      return state;
  }
}

export const Creators = {
  openChat: id => ({
    type: Types.OPEN_CHAT,
    payload: {
      id
    }
  }),

  chatSearch: searchTerm => ({
    type: Types.FILTER_CHAT,
    payload: {
      searchTerm
    }
  }),

  sendMsg: (id, chatMsg) => ({
    type: Types.SEND_MSG,
    payload: {
      id,
      chatMsg
    }
  })
};
