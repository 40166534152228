/**
 * Action Types
 */
 export const Types = {
    FILE_REQUEST: 'OFFER_FILE_REQUEST',
    FILE_SUCCESS: 'OFFER_FILE_SUCCESS',
    FILE_FAILURE: 'OFFER_FILE_FAILURE',
  };
  
  /**
   * Reducers
   */
  const INITIAL_STATE = {
    loading: false,
    error: false,
  };
  
  export default function offer(state = INITIAL_STATE, action) {
    switch (action.type) {
      case Types.FILE_REQUEST:
        return { ...state, loading: true };
      case Types.FILE_SUCCESS:
        return {
          ...state,
          error: false,
          loading: false,
        };
      case Types.FILE_FAILURE:
        return {
          ...state,
          error: true,
          loading: false,
        };
      default:
        return state;
    }
  }
  
  /**
   * Actions Creators
   */
  export const Creators = {
    fileRequest: (file, name, size, type, event_id, date, amount) => ({
      type: Types.FILE_REQUEST,
      payload: {
        file,
        name,
        size,
        type,
        event_id,
        date,
        amount
      },
    }),
  
    fileSuccess: () => ({
      type: Types.FILE_SUCCESS,
    }),
  
    fileFailure: () => ({
      type: Types.FILE_FAILURE,
    }),
  };
  