/**
 * Action Types
 */
export const Types = {
  ALL_NEWS_REQUEST: 'ALL_NEWS_REQUEST',
  ALL_NEWS_SUCCESS: 'ALL_NEWS_SUCCESS',
  ALL_NEWS_FAILURE: 'ALL_NEWS_FAILURE',
};

/**
 * Reducers
 */
const INITIAL_STATE = {
  loading: false,
  error: false,
  allNews: [],
  newsData: null,
};

export default function news(state = INITIAL_STATE, action) {
  switch (action.type) {
    // CASE CARREGAR A TABELA DE TODOS OS EVENTOS
    case Types.ALL_NEWS_REQUEST:
      return { ...state, loading: true };
    case Types.ALL_NEWS_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        allNews: action.payload.allNews,
      };
    case Types.ALL_NEWS_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      };

    default:
      return state;
  }
}

/**
 * Actions Creators
 */
export const Creators = {
  // CREATORS PARA TODOS OS EVENTOS
  allNewsRequest: () => ({
    type: Types.ALL_NEWS_REQUEST,
  }),
  allNewsSuccess: allNews => ({
    type: Types.ALL_NEWS_SUCCESS,
    payload: {
      allNews,
    },
  }),
  allNewsFailure: () => ({
    type: Types.ALL_NEWS_FAILURE,
  })
};
